.tariffs {
    padding: 1rem;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        margin: 0 auto;
        margin-bottom: 1rem;
    }


    .table-container {
        width: 95%;
        margin: 0 auto;

        // background-color: yellow;

        .search-container {
            width: 100%;
            margin-bottom: .5rem;
        }

        .search-container input {
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 100%;
            max-width: 400px;
        }

        .tariftable {
            border-collapse: collapse;
            width: 100%;
            // background-color: red;
        }

        .tariftable td,
        .tariftable th {
            padding: 12px 15px;
            border: 1px solid #ddd;
            text-align: center;
            font-size: 16px;
        }

        .tariftable th {
            background-color: #367cbd;
            color: #ffffff;
        }

        .tariftable tbody tr:nth-child(even) {
            background-color: #f5f5f5;
        }

        // .tfoot {
        //     display: flex;
        //     gap: .5rem;
        //     padding: .5rem 0;

        //     button {
        //         padding: .2rem 0.3rem;
        //         cursor: pointer;
        //         border: 1px solid lightgray;
        //         font-size: medium;
        //     }
        // }

        @media(max-width: 900px) {
            .back-btn {
                margin-left: .4rem;
            }

            .search-container input {
                margin-left: .04rem;
                width: 50%;
            }

            .tariftable thead {
                display: none;
            }

            .tariftable,
            .tariftable tbody,
            .tariftable tr,
            .tariftable td {
                display: block;
                // width: 100%;
            }

            .tariftable tr {
                margin-bottom: 15px;
            }

            .tariftable td {
                text-align: right;
                padding-left: 50%;
                text-align: right;
                position: relative;
            }

            .tariftable td::before {
                content: attr(data-label);
                position: absolute;
                left: 0;
                width: 50%;
                padding-left: 15px;
                font-size: 15px;
                font-weight: bold;
                text-align: left;
            }

            .tariftable tbody tr:nth-child(even) {
                background-color: #f5f5f5;
            }
        }

        @media(max-width: 500px) {
            .tariftable td {
                font-size: medium;
            }
        }
    }

}

@media screen and (max-width: 1200px) {
    .tariffs {
        .search {
            border-top: none;
            border-right: none;
        }

        .table-container {
            .container {
                .heading {
                    .head {
                        font-size: medium;
                    }
                }

                .tbody {
                    .tcell {
                        font-size: .7rem;
                    }
                }
            }
        }
    }
}