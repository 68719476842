body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;

  #page {
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-areas:
      "head head"
      "nav  main"
      "nav  foot";
    grid-template-rows: 100px 1fr 80px;
    grid-template-columns: 400px 1fr;
  }

  #page > header {
    grid-area: head;
    background-color: rgb(185, 233, 233);
    padding: 20px;

    .header-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 70px;

      .logo-wrapper {
        width: 15%;
      }

      .show {
        visibility: visible !important;
      }

      .icon-wrapper {
        width: 85% !important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left,
        .right {
          display: flex;
          gap: 20px;
        }

        .left {
          .input {
            visibility: hidden;
            position: absolute;
            width: 200px;
            height: auto;
            background-color: whitesmoke;
            z-index: 100;
            border-radius: 25px;
            padding: 20px;

            option {
              padding: 5px;
              border-radius: 10px;
            }

            option:nth-child(odd) {
              background-color: #ccc;
            }
          }

          // label:hover {
          //   .input {
          //     // display: block !important;
          //     visibility: visible;
          //   }
          // }
        }

        .right {
          // .right:first-child {
          //   width: 100%;
          //   height: 100%;
          //   display: flex;
          //   align-items: center;
          //   gap: 20px;
          // }
          .user {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #ccc;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-weight: 800;

            .status {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: red;
              position: absolute;
              right: 0;
              bottom: 3px;
            }
          }
        }
      }
    }
  }

  #page > nav {
    grid-area: nav;
    background-color: rgb(182, 251, 228);
    position: relative;

    .nav-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .top,
      .bottom {
        width: 100%;

        ul {
          list-style: none;

          li {
            height: 50px;
          }

          li a {
            height: 100%;
            padding: 0 20px;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 7px;
          }
        }
      }
    }
    .close {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(67, 67, 198);
      color: #fff;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: -40px;
      visibility: hidden;

      p {
        font-size: 18px;
        font-weight: 800;
      }
    }

    .active {
      background-color: #ccc !important;
      color: black !important;
    }
  }

  #page > main {
    grid-area: main;
    padding: 10px 25px;
    background-color: #fff;

    .main-wrapper {
      width: 100%;
      height: 100%;

      .main-header-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;

        .left {
          display: flex;
          align-items: center;
          gap: 30px;

          div {
            display: flex;
            align-items: center;
          }

          .open-menu-icon {
            display: none;
          }
          .add-folder-btn {
            padding: 6px;
            border: 5px solid black;
            // background: rgb(1, 210, 1);
            border-radius: 50%;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            cursor: pointer;
          }
        }
      }

      // p {
      //   margin-bottom: 50px;
      // }

      // .folders {
      //   display: grid;
      //   gap: 25px;
      //   grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      // }
    }
  }

  #page > footer {
    grid-area: foot;
    padding: 20px 50px;
    background-color: rgb(185, 233, 233);

    p {
      text-align: center;
    }
  }

  @media (max-width: 992px) {
    #page {
      grid-template-columns: 80px 1fr;
    }

    .nav-name {
      display: none;
    }
  }

  @media (max-width: 768px) {
    #page {
      grid-template-areas:
        "head head"
        "main  main"
        "foot  foot";
      grid-template-columns: 1fr;

      .open-menu-icon {
        display: block !important;
      }
    }

    // @keyframes closing {
    //   0% {
    //     transform: translateX(0);
    //   }
    //   100% {
    //     transform: translateX(-250px);
    //   }
    // }

    #page > nav {
      position: absolute;
      left: -250px;
      // animation-name: closing;
      // animation-duration: 1.5s;

      .close {
        visibility: visible;
      }
    }

    @keyframes open {
      0% {
        transform: translateX(-250px);
      }
      100% {
        transform: translateX(0);
      }
    }

    .toggle-nav {
      width: 200px;
      height: calc(100% - 100px);
      top: 100px;
      left: 0 !important;
      animation-name: open;
      animation-duration: 1.5s;

      .nav-name {
        display: block !important;
      }
    }
  }
}
