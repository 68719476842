.navbar {
    background-color: #f7f7f7;
    box-shadow: 0 3px 5px rgba(67, 67, 67, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .2rem 2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    // color: #00a85a;
    // color: darkgreen;
    color: rgba(53, 53, 53, 0.863);

    .logo {
        img {
            height: 70px;
            border-radius: 3px;
        }
    }

    .heading {
        font-size: 1.8rem;
        // color: #3d3f9c;
        font-weight: bold;
        letter-spacing: .05rem;
    }

    .profile1 {
        display: flex;
        align-items: center;
        gap: 2rem;
        cursor: pointer;

        .icon {
            font-size: 2rem;
            // color: #3d3f9c;
        }

        .person {
            border-radius: 50%;
            border: 1px solid green;
            width: 60px;
        }
    }
}

@media (max-width: 580px) {
    .navbar {
        padding: .2rem .5rem;

        .logo {
            img {
                height: 50px;
                width: 150px;
                border-radius: 3px;
            }
        }

        .heading {
            font-size: 1rem;
            // color: #3d3f9c;
            font-weight: bold;
            letter-spacing: 0rem;
            // min-width: max-content;
        }
    }
}