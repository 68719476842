.backBtnWrapper {
  width: 100%;
  padding: 20px 35px;

  button {
    padding: 8px 20px;
    width: auto;
    border-radius: 12px;
    font-size: larger;
    font-weight: 500;
    background: rgb(45, 45, 128);
    color: white;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
  }
}
