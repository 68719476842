.certificate-main {
    margin: 0 auto;
    min-width: 100%;
    // background-color: red;
}
.view-certificate {
    position: relative;
    max-width: 800px;
    max-height: 600px;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    border:  40px solid #618597;
    padding: 30px;
    color: #333;
    font-family: "Open Sans", sans-serif;
    
    &:after {
        content: "";
        position:absolute;
        left: -5px;
        top: -5px;
        right: -5px;
        bottom: -5px;
        border: 2px solid #fff;
        z-index: 1000;
      }
      &:before {
        content: "";
        position:absolute;
        left: -35px;
        top: -35px;
        right: -35px;
        bottom: -35px;
        border: 2px solid #fff;
        z-index: 1000;
      }

      .business-name {
        font-size: 30px;
      }

    
    .certificate-frame-outline {
        max-width: 794px;
        max-height: 594px;
        position: absolute;
        left: 50%;
        margin-left: -397px;
        top: 50%;
        margin-top: -297px;
        border: 2px solid #fff;
    }


    .certificate-frame-inner-outline {
        width: 730px;
        // height: 530px;
        position: absolute;
        left: 50%;
        margin-left: -365px;
        top: 50%;
        margin-top: -265px;
        // border: 2px solid #fff;
    }

    .certificate-header {
        margin: 0 auto;
    }

    .certificate-title {
        font-weight: 550;

    }

    .director-title {
        font-weight: 600;
        font-size: 17px;
    }
    .signature-image {
      width: 100px;
      height: 50px;
    }

}

@media only screen and (max-width: 600px) {

    .certificate-main {
        padding: 10px

    }
    .view-certificate {
        margin: 10px auto;
    
        border:  20px solid #618597;
        padding: 10px;
        color: #333;

        &:after {
            content: "";
            position:absolute;
            left: -10px;
            top: -10px;
            right: -10px;
            bottom: -10px;
            border: 1px solid #fff;
            z-index: 1000;
          }
          &:before {
            content: "";
            position:absolute;
            left: -30px;
            top: -30px;
            right: -30px;
            bottom: -30px;
            border: 1px solid #fff;
            z-index: 1000;
          }
    }
    .gppa-title {
        font-weight: 600;
        margin-top: 20px;
    }
    .gppa-sub-title {
        margin-top: 10px;  
        font-size: 14px;
        font-weight: 600; 
    }

    .director-title {
        font-weight: 600;
        font-size: 14px;
    }

    .barcode {
        width: 50px;
        height: 50px;
    }

    .barcode-img {
        width: 50px;
        height: 50px;
    }
}


@media only screen and (max-width: 400px) {

    .certificate-main {
        margin: 0 auto;
    }
    .view-certificate {
        // margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        
        border:  20px solid #618597;
        padding: 30px;
        color: #333;
        &:after {
            content: "";
            position:absolute;
            left: -4px;
            top: -4px;
            right: -4px;
            bottom: -4px;
            border: 1px solid #fff;
            z-index: 1000;
            // background-color: red;
          }
          &:before {
            content: "";
            position:absolute;
            left: -17px;
            top: -17px;
            right: -17px;
            bottom: -17px;
            border: 1px solid #fff;
            z-index: 1000;
            // background-color: red;
          }
    }
    .business-name {
      font-size: 12px;
    }

    .certificate-phara {
      font-size: 12px;
    }

    .certificate-title {
      font-size: 14px;
        font-weight: 550;
        margin-top: 10px;
    }

    .gppa-title {
      font-size: 13px;
    }

    .gppa-sub-title {
        margin-top: 10px;  
        font-size: 13px;
        font-weight: 600; 
    }
    .director-title {
        font-weight: 600;
        font-size: 14px;
    }

    .certificate-footer {
      font-size: 10px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .barcode {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }

    .barcode-img {
      width: 100%;
      height: 100%;
    }

  .certificate-id {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .signature-image {
    width: 100px;
    height: 50px;
  }
}



  
.certificate-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.barcode {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.barcode-img {
  width: 100%;
  height: 100%;
}

.certificate-id {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: red; */
}

