// // .logoutt {
// //     position: fixed;
// //     top: 6rem;
// //     right: 1rem;
// //     background-color: white;
// //     border: 1.5px solid #3e4095;
// //     padding: 2rem;
// //     border-radius: 5px;
// //     width: 300px;

// //     .person-containerr {
// //         display: flex;
// //         justify-content: center;

// //         .person1 {
// //             border-radius: 50%;
// //             width: 80px;
// //             border: 1px solid gray;
// //         }
// //     }

// //     button {
// //         background-color: blue;
// //         padding: .5rem;
// //         border: none;
// //         color: white;
// //         border-radius: 5px;
// //         cursor: pointer;
// //         display: flex;
// //         justify-content: center;
// //     }

// //     .btn {
// //         display: flex;
// //         justify-content: center;
// //         align-items: center;
// //         gap: .2rem;
// //         margin-top: .5rem;
// //         background-color: blue;
// //         padding: .5rem .2rem;
// //         border: none;
// //         color: white;
// //         border-radius: 5px;
// //         cursor: pointer;

// //         .log {
// //             font-size: 1.3rem;
// //         }

// //         .icon {
// //             font-size: 1.3rem;
// //         }
// //     }

// //     .btn-close {
// //         display: none;
// //     }

// //     .user {
// //         text-align: center;
// //         margin: 1rem;
// //     }

// //     .open-pass {
// //         display: flex;
// //         align-items: center;
// //         justify-content: center;
// //         color: blue;
// //         font-weight: bold;
// //         letter-spacing: .1rem;
// //     }

// //     .change-pass {
// //         text-align: center;
// //         display: flex;
// //         justify-content: center;

// //         input {
// //             display: flex;
// //             margin: .5rem 0;
// //             padding: .4rem .4rem;
// //             width: 300px;
// //             border-radius: 3px 0 0 3px;
// //             border: 1px solid gray;
// //         }

// //         .change {
// //             display: flex;
// //             justify-content: center;

// //             button {
// //                 width: 100%;
// //                 padding: .5rem .2rem;
// //                 font-size: 1.1rem;
// //             }
// //         }

// //         .input-icon {
// //             display: flex;
// //             align-items: center;

// //             .visibility {
// //                 border: 1px solid gray;
// //                 border-left: none;
// //                 padding: 0 .1rem;
// //                 border-radius: 0 3px 3px 0;
// //             }
// //         }
// //     }

// //     .change-pass-close {
// //         display: none;
// //     }
// // }

// // @media (min-width: 500px) {
// //     .logout {
// //         .user {
// //             .insidee {
// //                 display: none;
// //             }
// //         }
// //     }
// // }

// .logout {
//     position: fixed;
//     top: 6rem;
//     right: 1rem;
//     background-color: white;
//     border: 1.5px solid #3e4095;
//     padding: 2rem;
//     border-radius: 5px;
//     width: 300px;

//     .person-container {
//         display: flex;
//         justify-content: center;

//         .person1 {
//             border-radius: 50%;
//             width: 80px;
//             border: 1px solid gray;
//         }
//     }

//     button {
//         background-color: blue;
//         padding: .5rem;
//         border: none;
//         color: white;
//         border-radius: 5px;
//         cursor: pointer;
//         display: flex;
//         justify-content: center;
//     }

//     .btn {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         gap: .2rem;
//         margin-top: .5rem;
//         background-color: blue;
//         padding: .5rem .2rem;
//         border: none;
//         color: white;
//         border-radius: 5px;
//         cursor: pointer;

//         .log {
//             font-size: 1.3rem;
//         }

//         .icon {
//             font-size: 1.3rem;
//         }
//     }

//     .btn-close {
//         display: none;
//     }

//     .user {
//         text-align: center;
//         margin: 1rem;
//     }

//     .open-pass {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         color: blue;
//         font-weight: bold;
//         letter-spacing: .1rem;
//     }

//     .change-pass {
//         text-align: center;
//         display: flex;
//         justify-content: center;

//         input {
//             display: flex;
//             margin: .5rem 0;
//             padding: .4rem .4rem;
//             width: 300px;
//             border-radius: 3px 0 0 3px;
//             border: 1px solid gray;
//         }

//         .change {
//             display: flex;
//             justify-content: center;

//             button {
//                 width: 100%;
//                 padding: .5rem .2rem;
//                 font-size: 1.1rem;
//             }
//         }

//         .input-icon {
//             display: flex;
//             align-items: center;

//             .visibility{
//                 border: 1px solid gray;
//                 border-left: none;
//                 padding: 0 .1rem;
//                 border-radius: 0 3px 3px 0;
//             }
//         }
//     }

//     .change-pass-close {
//         display: none;
//     }
// }


.logout {
    position: fixed;
    top: 6rem;
    right: 1rem;
    background-color: white;
    border: 1.5px solid #3e4095;
    padding: 2rem;
    border-radius: 5px;
    width: 300px;

    .person-container {
        display: flex;
        justify-content: center;

        .person1 {
            border-radius: 50%;
            width: 80px;
            border: 1px solid gray;
        }
    }

    button {
        background-color: blue;
        padding: .5rem;
        border: none;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .2rem;
        margin-top: .5rem;
        background-color: blue;
        padding: .5rem .2rem;
        border: none;
        color: white;
        border-radius: 5px;
        cursor: pointer;

        .log {
            font-size: 1.3rem;
        }

        .icon {
            font-size: 1.3rem;
        }
    }

    .btn-close {
        display: none;
    }

    .user {
        text-align: center;
        margin: 1rem;
    }

    .open-pass {
        display: flex;
        align-items: center;
        justify-content: center;
        color: blue;
        font-weight: bold;
        letter-spacing: .1rem;
    }

    .change-pass {
        text-align: center;
        display: flex;
        justify-content: center;

        input {
            display: flex;
            margin: .5rem 0;
            padding: .4rem .4rem;
            width: 300px;
            border-radius: 3px 0 0 3px;
            border: 1px solid gray;
        }

        .change {
            display: flex;
            justify-content: center;

            button {
                width: 100%;
                padding: .5rem .2rem;
                font-size: 1.1rem;
            }
        }

        .input-icon {
            display: flex;
            align-items: center;

            .visibility {
                border: 1px solid gray;
                border-left: none;
                padding: 0 .1rem;
                border-radius: 0 3px 3px 0;
            }
        }
    }

    .change-pass-close {
        display: none;
    }
}

@media (min-width: 500px) {
    .logout {
        .user {
            .insidee {
                display: none;
            }
        }
    }
}