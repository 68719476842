.create-btn {
  font-size: 1.1rem;
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
  background-color: #03a65b;
  padding: 0.7rem 1rem;
  border-radius: 0.1rem;
  //   margin-top: 1.5rem;
  margin: 1.5rem 2rem;
  border-radius: 0.2rem;
}

.file-manager {
  // position: relative;
  &__content {
    display: grid;
    grid-template-columns:
      [full-start]5rem 1fr repeat(8, [col-start] minmax(min-content, 14rem))
      [col-end] 1fr 5rem[full-end];

    position: relative;

    &--sub-header {
      grid-column: col-start 1 / col-end;

      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-top: 2rem;

      .content-title {
        font-size: 1.7rem;
        text-transform: capitalize;
        text-align: center;
      }
    }
    &--list {
      grid-column: col-start 1 / col-end;

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(6rem, 6rem));
      grid-row-gap: 1rem;
      grid-column-gap: 4rem;
      margin-top: 2rem;
      .folder {
        text-decoration: none;

        &__icon {
          width: 6rem;
          height: 6rem;
          fill: #f2cf64;
        }

        &__name {
          font-size: 16px;
          text-transform: capitalize;
          width: 6rem;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          margin-top: 1rem;
        }
      }
    }
  }
}
