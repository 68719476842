.modal-overlay{
    .title {
        font-size: 1.5rem;
        text-transform: capitalize;
    }
    .form-input {
        margin-top: -1rem;

        input {
            padding: .7rem .5rem;
        }

    }

    .file {
        width: 21rem;
      }
      

      .file-input-label {
        margin-left: 1rem;
      }
      
      .file > input[type='file'] {
        display: none
      }
      
      .file > label {
        font-size: 1rem;
        font-weight: 300;
        cursor: pointer;
        outline: 0;
        user-select: none;
        border-color: hsl(141, 71%, 48%);
        border-style: solid;
        border-radius: 4px;
        border-width: 1px;
        background-color: hsl(0, 0%, 100%);
        color: hsl(0, 0%, 29%);
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 12px;
        padding-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .file > label:hover {
        border-color: hsl(141, 71%, 48%);
        background-color: hsl(141, 71%, 96%);
      }
      

    .file-upload-btn {

      width: 21rem;
        color: #fff;
        background-color: #03A65B;
        padding: .3rem 1rem;
        border-radius: .2rem;
        // margin-top: 2rem;
        text-transform: capitalize;
        cursor: pointer;
        // margin-right: 5rem;s

        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .select-input {
        // width: 100%;
        width: 21rem;
    }

    .textarea {
        outline: none;
        border: none;
        width: 21rem;
        padding: 0.5rem;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
        outline: none;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

}