.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);


}

.modal-header {
  border-bottom: none;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-body {
  padding: 2rem;
}

.folder-form {
  background-color: white;
  width: 30rem;
  margin: 20vh auto;
  padding: 3rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.folder-input {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: column;

  .create-form-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  label {
    //   display: block;
    margin-bottom: 0.5rem;
  }

  input {
    width: 20rem;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      outline: none;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  .submit-btn {
    background-color: green;
    border-radius: .2rem;
    padding: .5rem 1rem;
    color: #fff;
    cursor: pointer;
  }
}

.btn-close {
  color: #0000;
  font-size: 1.5rem;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}


.error-message {
  color: red;
  margin-top: -1rem;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
}


.btn-close {
  fill: #000;
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 2rem;
  top: 2rem;
}

@media screen and (max-width: 500px) {
  .folder-form {
    width: 18rem;
    margin: 20vh auto;
    padding: 2rem;
  }

  .folder-input {

    .create-form-content {
      gap: .5rem;
    }

    input {
      width: 15rem;
    }
  }
}