.file-manager {
    .sub-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .upload-btn {
            color: #fff;
            background-color: #03A65B;
            padding: .5rem 1rem;
            border-radius: .2rem;
            margin-top: 2rem;
            text-transform: capitalize;
            cursor: pointer;
            margin-right: 5rem;
        }
    }
    
    .back-btn {
        background-color: #03A65B;
        padding: .5rem 1rem;
        border-radius: .2rem;
        margin-top: 2rem;
        text-transform: capitalize;
        cursor: pointer;
    }

     .content-title {
        grid-column: col-start 1 /  col-end;
        font-size: 1.7rem;
        text-transform: capitalize;
        margin-right: auto;
        margin-top: 2rem;
    }
    &__content {
        display: grid;
        grid-template-columns: [full-start]5rem 1fr repeat(8,[col-start] minmax(min-content, 14rem))[col-end] 1fr  5rem[full-end];

        position: relative;
        
        &--list {     
            grid-column: col-start 1 / col-end;
            
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(6rem, 6rem));
            grid-row-gap: 1rem;
            grid-column-gap: 4rem;
            margin-top: 2rem;
            .void-folder {
                width: 100vw;
                margin: 2rem auto;
                text-align: center;
                text-transform: capitalize;
            }
            .file {
                width: 6rem;
                text-decoration: none;
                &__icon {
                    width: 6rem;
                    height: 6rem;
                    
                }
                
                &__name {
                    text-decoration: none;
                    color: #333;
                    font-size: 16px;
                    text-transform: capitalize;
                    width: 6rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    margin-top: 1rem;
                    
                }
            }

           
            
        }
    }
    

}
